<template>
  <div class="home-page">
    <BgHeaderContainer 
      :is-home="isHome" 
      :is-editing-layout="isEditingLayout" 
      :can-edit-layout="canEditLayout" 
    />
    <EditLayoutActions 
      v-if="isEditingLayout && canEditLayout" 
      class="layout-actions first-actions-group" 
      @toggle-layout-edited="checkLayout"
    />
    <EditLayoutTemplateActions 
      v-if="isEditingLayout && canEditLayout" 
      class="layout-actions second-actions-group" 
    />
    <!--AvailableAppsForLayout v-if="isEditingLayout && canEditLayout" class="layout-actions third-actions-group" /-->
    <!--ElementsLayoutActions v-if="isEditingLayout && canEditLayout" class="element-actions" /-->
    <AppsLayout 
      v-if="hasEnabledAppsOrElements || (isEditingLayout && canEditLayout) || layoutEdited" 
      @reloaded.once="scrollWindow" 
      :elems="spaceElements"
    />
    <EmptyLayout v-else />
  </div>
</template>

<script>
// import BgHeaderContainer from '@core/components/bg/BgHeaderContainer.vue';
// import AppsLayout from '@core/components/layouts/apps-layout/AppsLayout.vue';
// import ElementsLayoutActions from '@core/components/layouts/apps-layout/elements-layout/ElementsLayoutActions.vue';
// import EditLayoutActions from '@core/components/layouts/apps-layout/EditLayoutActions.vue';
// import EmptyLayout from '@core/components/layouts/apps-layout/EmptyLayout.vue';
import GridStatusMixin from '@core/mixins/apps-layout/GridStatusMixin';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import { startNotifications, sendMixpanelNotification } from '@/@core/utils/notifications/notifications';

import { mapGetters } from 'vuex'
import { promiseTimeout } from '@vueuse/core'

export default {
  name: 'Home',

  // inject: ['hasEntered'],

  components: {
    BgHeaderContainer: () => import("@core/components/bg/BgHeaderContainer.vue" /* webpackChunkName: "BgHeaderContainer" */),
    AppsLayout: () => import("@core/components/layouts/apps-layout/AppsLayout.vue" /* webpackChunkName: "AppsLayout" */),
    EditLayoutActions: () => import("@core/components/layouts/apps-layout/EditLayoutActions.vue" /* webpackChunkName: "EditLayoutActions" */),
    EmptyLayout: () => import("@core/components/layouts/apps-layout/EmptyLayout.vue" /* webpackChunkName: "EmptyLayout" */),
    //ElementsLayoutActions: () => import('@core/components/layouts/apps-layout/elements-layout/ElementsLayoutActions.vue' /* webpackChunkName: "ElementsLayoutActions" */),
    EditLayoutTemplateActions: () => import("@core/components/layouts/apps-layout/EditLayoutTemplateActions.vue" /* webpackChunkName: "EditLayoutTemplateActions" */),
    //AvailableAppsForLayout: () => import("@core/components/layouts/apps-layout/AvailableAppsForLayout.vue" /* webpackChunkName: "EditLayoutActions" */),
},

  mixins: [GridStatusMixin],

  data() {
    return {
      bannerChanged: null,
      bannerStyle: null,
      componentKey: 0,
      //layoutEdited: false,
      // isLoading: false,
      //hasEnabledAppsOrElements: false,
    };
  },

  computed: {
    ...mapGetters([ 
      'loggedMember',
    ]),

    collective() {
      return this.$store.getters.currentCollective;
    },
    isReady() {
      return this.hasEntered();
    },
    isAdmin() {
      // FIXME: This seems broken. We are availing it by default.
      // return this.$store.getters.loggedUser.isAdmin;
      return true;
    },
    isHome() {
      return this.$route.name === 'Resumen';
    },
    canEditLayout() {
      return this.isHome && this.isAdmin && !this.isGridDisabled;
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    isLayoutEdited() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isLayoutEdited];
    },
    layoutEdited() {
      //console.log('this.savedLayout', this.savedLayout);
      return this.savedLayout & this.savedLayout !== [] ? this.savedLayout : false;
    },
    savedLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.savedLayout];
    },
    enabledApps() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps];
    },
    spaceElements() {
      return this.collective?.elements ? Object.entries(this.collective.elements) : [];
    },
    hasEnabledApps() {
      return Object.entries(this.enabledApps).length > 0;
    },
    hasEnabledElements() {
      return this.spaceElements?.length > 0;
    },
    hasEnabledAppsOrElements() {
      return this.hasEnabledApps || this.hasEnabledElements;
    },
  },

  watch: {
    // isReady(isReady) {
    //   if (isReady && this.$route.hash) {
    //     window.location.href = this.$route.hash;
    //   }
    // },
    hasEnabledAppsOrElements() {
      return this.hasEnabledApps || this.hasEnabledElements;
    },
    isLayoutEdited() {
      const isLayoutEdited = this.$store.getters[ENABLED_APPS_GETTERS.isLayoutEdited];
      //console.log('isLayoutEdited?', isLayoutEdited);
      return isLayoutEdited;
    },
  },

  methods: {
    // async getEnabledAppsOrElements() {
    //   console.log('getEnabledAppsOrElements...')
    // },

    async scrollWindow () {
      // console.log('scrolled!!!!!!!!!!')
      await promiseTimeout(800)
      // console.log('scrolled timeout 800 ')
      this.onScrollToWindow()
    },

    onScrollToWindow() {
      window.scrollTo(0, 1);  //this is a patch to load content
    },
    checkLayout(){
      console.log('Emit recibido!!');
      this.layoutEdited = true;
    },
  },

  async created() {
    //console.log('HOME CREATED. Has enabled apps or elements?', this.hasEnabledAppsOrElements);
    //this.hasEnabledAppsOrElements = this.hasEnabledApps || this.hasEnabledElements;
    // if (!this.hasEnabledAppsOrElements) {
    //   await this.getEnabledAppsOrElements()
    // }
    
    await startNotifications({ 
      memberKey: this.loggedMember.key,
      communitySlug: this.collectiveSlug
    });

    await sendMixpanelNotification('member-in-community-home');

    // console.log('END HOME CREATED: ', this.hasEnabledAppsOrElements)
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/home.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes
@import '~@core/scss/base/components/variables';

.home-page {
  min-height: 1000px !important;
}

.layout-actions {
  position: fixed;
  background: $white;
  border: 2px solid $primary;
  border-right: none;
  border-top-left-radius: $border-radius-lg;
  border-bottom-left-radius: $border-radius-lg;
  z-index: 999;

  //top: calc(#{$content-padding} + #{$navbar-height} + #{$spacer * 0.6});
  right: 0;
  padding: calc($spacer/2) $spacer calc($spacer/2) $spacer;
}
.first-actions-group {
  top: 50px;
}
.second-actions-group {
  top: 100px;
}
.third-actions-group {
  top: 150px;
}

.element-actions {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  margin: 0 auto;
  padding: 5px 10px;
  right: 28px
}
</style>
